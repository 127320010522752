<template>
  <div class="invite-friend text-center f-w6">
    <p>PreGoをシェアしよう！</p>
    <div class="qrcode">
      <vue-qr :text="value" :size="size" :margin="1" class="qr-image"></vue-qr>
    </div>
    <div class="prego__link">{{ value }}</div>
    <button class="f-w3" v-clipboard:copy="value" v-clipboard:success="onCopy">
      招待URLをコピーする
    </button>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import { SIZE_QR_PC } from "../constants/shareApp";

export default {
  name: "InviteFriend",
  data() {
    return {
      value: process.env.VUE_APP_MY_ENV_BASE_URL,
      size: SIZE_QR_PC
    };
  },
  created() {
    const user = this.$store.getters["auth/user"];
    let inviteCode = user.invitation_code;
    if (inviteCode) {
      this.value += `/share-app?code=${inviteCode}`;
    }
  },
  components: {
    VueQr
  },
  methods: {
    onCopy: function() {
      this.$emit("showNotification");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_fontFamily.scss";
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/inviteFriend.scss";
</style>
